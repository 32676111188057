<template>
  <v-dialog
    v-model="isShow"
    max-width="800"
    @click:outside="$emit('cancel')"
  >
    <v-card>
      <v-card-title>利用規約</v-card-title>
      <v-card-text class="terms">
        <article>
          <section>
            <h2>お客様の定義</h2>
            <ol>
              <li>「お客様」とは、当社が提供するライブ視聴やチケット・商品の購入を目的とするサービス（以下「本サービス」という）を利用される方をいいます。</li>
              <li>本サービスを利用した場合は、本規約にお客様が同意したものとみなされます。</li>
              <li>利用者が未成年の場合には、事前に法定代理人（親権者等）の同意を得たうえで利用しているものとみなします。</li>
            </ol>
          </section>
          <section>
            <h2>配信者の定義</h2>
            <ol>
              <li>「配信者」とは、本サービスを利用してライブ配信やチケット・商品の販売を行う方をいいます。</li>
              <li>本サービスを利用した場合は、本規約に配信者が同意したものとみなされます。</li>
            </ol>
          </section>
          <section>
            <h2>本規約の適用範囲および変更</h2>
            <ol>
              <li>本規約は、本サービスの利用に関し、当社及びお客様、配信者に適用されるものといたします。</li>
              <li>本サービスに関連して提携他社との共同により行うサービスが別途ある場合については、当該サービスの対象者においても適用されるものといたします。</li>
              <li>本規約に添付されている「プライバシーポリシー」、その他の個別規定及び今後ご提供する新サービスごとに規定する個別規定は、本規約の一部を構成いたします。</li>
            </ol>
          </section>
          <section>
            <h2>規約の変更</h2>
            <ol>
              <li>当社は、本規約の変更が、本規約に基づく契約の目的に反せず、かつ、変更の必要性、変更後の内容の相当性その他の変更にかかる事情に照らして合理的なものであるときは、民法548条の4の規定により、本規約および個別規約を変更することができるものとします。</li>
              <li>本規約および個別規約を変更した場合、料金その他の本サービスに関する一切の事項は変更後の規約によるものとします。</li>
            </ol>
          </section>
          <section>
            <h2>利用環境</h2>
            <ol>
              <li>お客様・配信者は、事故の責任と負担において、本サービスを利用するために必要な通信機器、ソフトウェア、電話利用契約およびインターネット接続契約等を準備していただきます。</li>
              <li>本サービス利用（お問合せを含む）に関わる一切の通信料、接続料等はお客様にご負担いただくものといたします。</li>
              <li>本サービスを利用して視聴方法、配信方法の詳細は、サービス画面をご参照ください。</li>
            </ol>
          </section>
          <section>
            <h2>コンテンツの購入</h2>
            <ol>
              <li>本サービスには、有料コンテンツがあります。有料コンテンツを利用する場合には、本サービス上に表示される有料コンテンツごとに設定された単価（以下「購入料金」といいます）をお支払いいただく必要がございます。</li>
              <li>有料コンテンツは、運営上その他の理由によって変更される場合があり、利用期間終了まで提供されるとは限らないこと予め了承します。</li>
              <li>有料コンテンツのご利用は、以下の事項が発生した場合終了するものとします。
                <ol>
                  <li>有料コンテンツに利用期限が定められている場合において、当該利用可能期間が終了したとき</li>
                  <li>利用者が有料コンテンツの購入に登録している電話番号、メールアドレスの使用を終了したとき</li>
                  <li>配信者により有料コンテンツが削除されたとき</li>
                </ol>
              </li>
            </ol>
          </section>
          <section>
            <h2>購入料金の返還</h2>
            <ol>
              <li>お客様の都合によりサービス利用が不可能になった場合、いかなる理由があっても、既に支払われた有料コンテンツの購入料金の返還を一切いたしません。</li>
              <li>当社もしくは配信者の都合により有料コンテンツが提供されなかった場合、有料コンテンツの提供状況から返還金額を鑑み、既に支払われた有料コンテンツの購入料金の返還をいたします。</li>
            </ol>
          </section>
          <section>
            <h2>権利の帰属</h2>
            <ol>
              <li>配信コンテンツの著作権その他一切の知的財産権は、当該配信者又は配信者に権利を許諾した第三者に帰属するものとします。</li>
              <li>配信者は、当社に対して、配信コンテンツを自由に利用できる世界的、非独占的、無償、サブライセンス可能かつ譲渡可能な許諾ライセンスを付与するものとします。</li>
              <li>当社は、本サービスの企画・運営、共同研究およびプロモーション等における配信コンテンツの利用に際し、当社が必要とする範囲において配信コンテンツを改変できるものとし、配信者はこれを予め承諾するものとします。</li>
            </ol>
          </section>
          <section>
            <h2>禁止事項</h2>
            <ol>
              <li>配信者は、本サービスの利用にあたって以下の行為を行ってはならないものといたします。
                <ul>
                  <li>他のお客様、第三者又は当社の著作権、財産権、プライバシーもしくはその他の権利を侵害する行為、および侵害するおそれのある行為</li>
                  <li>他のお客様、第三者又は当社に不利益もしくは損害を与える行為、および与えるおそれのある行為</li>
                  <li>公序良俗に反する行為、もしくはそのおそれのある行為、または公序良俗に反する情報を他のお客様または第三者に提供する行為</li>
                  <li>犯罪的行為、もしくは犯罪的行為に結び付く行為、またはそのおそれのある行為</li>
                  <li>選挙期間中であるか否かを問わず、選挙運動又はこれに類する行動</li>
                  <li>わいせつな表現またはヌード画像を含むものを配信する行為</li>
                  <li>個人または団体に対して差別、偏見、人種差別、憎悪、嫌がらせ又は侵害を助長する行為</li>
                  <li>暴力的もしくは脅迫的である、または他者に対して暴力的もしくは脅迫的な行為を助長する行為</li>
                  <li>性風俗、宗教、政治に関する活動</li>
                  <li>IDまたはパスワードを不正に使用する行為</li>
                  <li>第三者へのアカウントの譲渡</li>
                  <li>他の個人または団体になりすまし、またはほかの個人または団体と関係があるように不当に見せかけること</li>
                  <li>コンピュータウイルス等の有害なプログラムを、本サービスに関連して使用、もしくは提供する行為</li>
                  <li>法令に違反する、または違反する恐れのある行為</li>
                  <li>その他、当社が不適切と判断する行為</li>
                </ul>
              </li>
              <li>お客様は、本サービスの利用にあたって以下の行為を行ってはならないものといたします。
                <ul>
                  <li>他のお客様、第三者又は当社の著作権、財産権、プライバシーもしくはその他の権利を侵害する行為、および侵害するおそれのある行為</li>
                  <li>他のお客様、第三者又は当社に不利益もしくは損害を与える行為、および与えるおそれのある行為</li>
                  <li>公序良俗に反する行為、もしくはそのおそれのある行為、または公序良俗に反する情報を他のお客様または第三者に提供する行為</li>
                  <li>犯罪的行為、もしくは犯罪的行為に結び付く行為、またはそのおそれのある行為</li>
                  <li>IDまたはパスワードを不正に使用する行為</li>
                  <li>第三者への購入コンテンツの譲渡</li>
                  <li>本サービスの内容の無断転載・複写・蓄積または転送</li>
                  <li>コンピュータウイルス等の有害なプログラムを、本サービスに関連して使用、もしくは提供する行為</li>
                  <li>当社が提供するアプリケーション、当社が保有するサーバーおよびこれらが生成する情報、通信内容等の解読、解析、逆コンパイル、逆アセンブルまたはリバースエンジニアリング等の行為</li>
                  <li>ボットなどの自動化された手段を用いて本サービスを利用すること</li>
                  <li>本サービスのバグ、誤動作を利用すること</li>
                  <li>法令に違反する、または違反する恐れのある行為</li>
                  <li>その他、当社が不適切と判断する行為</li>
                </ul>
              </li>
            </ol>
          </section>
          <section>
            <h2>サービスの運営</h2>
            <ol>
              <li>当社はアクセス状況を監視し、禁止事項に掲げた行為が認められた場合など、当社が必要と判断した場合、アクセスの制限をすることができるものとします。</li>
            </ol>
          </section>
          <section>
            <h2>サービスの変更、中断または中止</h2>
            <ol>
              <li>当社は、以下のような場合、自らの判断により本サービスの運用の全部または一部を変更、中断または中止することができるものといたします。
                <ul>
                  <li>天災、事変、その他の非常事態が発生し、または発生するおそれがある場合</li>
                  <li>本サービスに関連して、当社が設置または管理する設備の保守を定期的にもしくは緊急に行う場合</li>
                  <li>当社が設置または管理する設備の異状、故障、障害その他本サービスをお客様に提供できない事由が生じた場合</li>
                  <li>当社が必要と判断した場合</li>
                </ul>
              </li>
              <li>当社は理由の如何を問わず、本サービスの変更、中断または中止によって生じたお客様の損害につき、一切責任を負わないものとします。</li>
            </ol>
          </section>
          <section>
            <h2>免責事項</h2>
            <ol>
              <li>お客様は、自己の判断と責任において本サービスを利用するものとし、運営会社は、利用者が本サービスを利用したことまたは利用できないことに起因または関連して生じた損害について、直接的又は間接的な損害を問わず、一切の責任を負わないものとします。ただし、損害の発生の直接の原因となる事由が、当社の故意または重過失に起因する場合には、この限りではありません。</li>
              <li>お客様は、運営会社が、配信者の配信コンテンツに関し、確実性、正確性、安全性、有用性、第三者権利侵害の有無、及び特定の目的への適合性のいずれについても保証するものではなく、また、法令等により要求される場合を除き、運営会社は配信コンテンツについて一切監視義務を負わないことを確認します。</li>
              <li>当社は、本サービスの利用については、サービス画面上に表示する推奨環境以外の環境からの利用の動作保証を一切するものではなく、また、推奨環境からの利用についても動作保証を一切行うものではありません。</li>
            </ol>
          </section>
          <section>
            <h2>個人情報の取り扱い</h2>
            <ol>
              <li>当社は、別途掲示する「プライバシーポリシー」に基づきお客様の個人情報を取り扱うものとし、お客様はプライバシーポリシーに同意の上本サービスをご利用いただくものとします。</li>
            </ol>
          </section>
          <section>
            <h2>分離性</h2>
            <ol>
              <li>本利用規約に定める条項の一部が強行法規への抵触その他の理由により無効とされた場合であっても、当該無効とされた条項以外の他の条項は有効に存続するものとします。この場合、当該無効とされた条項は、当初に意図された経済的目的が可能な限り達成できる有効な条項に当然に置き換えられるものとし、利用者はこれを予め承諾するものとします。</li>
            </ol>
          </section>
          <section>
            <h2>紛争</h2>
            <ol>
              <li>本サービスに関して生じたお客様、配信者間の紛争は、当事者間で解決するものとし、お客様は当社に対しいかなる苦情の申し立ても行わないこととします。</li>
            </ol>
          </section>
          <section>
            <h2>準拠法</h2>
            <ol>
              <li>本規約の成立、効力、履行及び解釈に関しては、日本法が適用されるものといたします。</li>
            </ol>
          </section>
          <section>
            <h2>管轄裁判所</h2>
            <ol>
              <li>本契約に関して万一、お客様と当社との間で紛争が生じた場合、当社の本店所在地を管轄する地方裁判所を合意管轄裁判所といたします。</li>
            </ol>
          </section>
          <section>
            <h2>その他</h2>
            <ol>
              <li>お客様及び配信者は、本規約に定めのない事項について、別途当社の定めるところに従うものといたします。</li>
            </ol>
          </section>
        </article>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'terms',
  props:{
    isShow: Boolean
  }
}
</script>

<style scoped>
.terms article{
  counter-reset: number 0;
}
.terms h2:before{
  counter-increment: number 1;
  content: "第" counter(number) "条 （";
}
.terms h2:after{
  content: "）";
}
</style>
